<template>
  <div v-if="!isLoading">
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              width="30%"
              class="text-uppercase text-center"
            >
              {{ $t('lbl.date') }}
            </th>
            <th
              width="20%"
              class="text-uppercase text-center"
            >
              {{ $t('menu.users') }}
            </th>
            <th
              width="10%"
              class="text-uppercase text-center"
            >
              {{ $t('lbl.action') }}
            </th>
            <th
              width="40%"
              class="text-uppercase text-center"
            >
              {{ $t('lbl.description') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="index"
          >
            <td class="text-center">
              {{ $moment(item.date_time).format('MMMM Do YYYY, h:mm a') }}
            </td>
            <td class="text-center">
              {{ item.user_email }}
            </td>
            <td class="text-center text-uppercase">
              {{ item.data_logs.action }}
            </td>
            <td class="text-center">
              <v-alert
                class="mt-2"
                outlined
                :type="item.status"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >{{ item.data_logs.entity | truncate(30) }}</span>
                  </template>
                  <span>{{ item.data_logs.entity }}</span>
                </v-tooltip>
              </v-alert>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import {
  mdiMagnify, mdiDeleteOutline, mdiSquareEditOutline, mdiPlus, mdiArrowLeft, mdiUpdate,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
// import LeafletExample from '@/views/leaflet/Example.vue'

export default {
  data() {
    return {
      isLoading: true,
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
      },
      loading: false,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.axios
        .get(`logs?page=${this.pagination.current}&per_page=${this.itemsPerPage}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.items = res.data.data
          this.pagination.current = res.data.meta.current_page
          this.pagination.total = res.data.meta.last_page
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    onPageChange() {
      this.load()
    },
  },
}
</script>
